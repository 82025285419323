import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledSelect from '@/components/Form/ControlledSelect';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';

import type { LinkAssessmentFormFields } from './LinkAssessmentFormSchema';

type Props = {
  assessmentOptions: {
    id: string;
    value: string;
    label: string;
    description?: string;
  }[];
};

export const LinkAssessmentForm: FC<Props> = ({ assessmentOptions }) => {
  const { t } = useTranslation(['common'], { keyPrefix: 'wizard' });
  const { control } = useFormContext<LinkAssessmentFormFields>();

  return (
    <CustomisableForm>
      <ControlledSelect
        key={'assessmentId'}
        defaultRequired={true}
        filteringType={'auto'}
        statusType={'finished'}
        label={t('forms.linkAssessmentLabel')}
        name={'AssessmentId'}
        description={t('forms.linkAssessmentHelp')}
        placeholder={t('forms.linkAssessmentPlaceholder')}
        control={control}
        options={assessmentOptions}
      />
    </CustomisableForm>
  );
};
