import Alert from '@cloudscape-design/components/alert';
import type { DataType } from '@risksmart-app/shared/reporting/datasets/types';
import _ from 'lodash';
import type { Ref } from 'react';
import { useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useDashboardWidgetSettings } from 'src/context/useDashboardWidgetSettings';
import type { TypedCustomDatasource } from 'src/pages/custom-datasources/types';
import {
  getFieldDefinition,
  getFieldFromValue,
} from 'src/pages/custom-datasources/update/utils';

import type { GroupBy, ReportingDataInput } from '@/generated/graphql';
import {
  useGetCustomDatasourceByIdQuery,
  useGetReportingDataQuery,
} from '@/generated/graphql';
import { customDatasourceUrl } from '@/utils/urls';

import type { WidgetRef } from '../types';
import ConfigureWidgetPanel from '../UniversalWidget/ConfigureWidgetPanel';
import { getFilterPropertyForCategory } from './categoryClickHandler';
import { CustomDataSourceWidgetSettingsModel } from './CustomDataSourceWidgetSettingsModel';
import {
  type CustomDataSourceWidgetSettings,
  customDataSourceWidgetSettingsSchema,
} from './form/customDataSourceWidgetSettingsSchema';
import { useCustomAttributeLookup } from './useCustomAttributeLookup';
import { useMapReportDataToSeries } from './useMapReportDataToSeries';
import type { ReportField } from './WidgetChart/types';
import { WidgetChart } from './WidgetChart/WidgetChart';

export const CustomDataSourceWidget = (
  _props: unknown,
  ref: Ref<WidgetRef>
) => {
  const navigate = useNavigate();
  const { customAttributeSchemaLookup } = useCustomAttributeLookup();
  const { t } = useTranslation(['common'], { keyPrefix: 'customDatasources' });
  const [settings, setSettings] =
    useDashboardWidgetSettings<CustomDataSourceWidgetSettings>();
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  useImperativeHandle(
    ref,
    (): WidgetRef => ({
      openSettings: () => {
        setShowSettingsModal(true);
      },
    })
  );
  const { success: validSettings } =
    customDataSourceWidgetSettingsSchema.safeParse(settings);

  const { data: customDatasourceResponse } = useGetCustomDatasourceByIdQuery({
    variables: settings?.customDataSourceId
      ? {
          Id: settings?.customDataSourceId,
        }
      : undefined,
    skip: !settings?.customDataSourceId,
    fetchPolicy: 'no-cache',
  });
  const selectedCustomDatasource: TypedCustomDatasource | undefined =
    customDatasourceResponse?.custom_datasource_by_pk ?? undefined;
  const getFieldByValue = (value: string) => {
    const field = getFieldFromValue(value);

    return selectedCustomDatasource?.Fields?.find(
      (f) =>
        f.fieldId === field.fieldId &&
        f.dataSourceIndex === field.dataSourceIndex
    );
  };

  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = settings?.chartType === 'table' ? 20 : 100000;
  const x1Field = getFieldByValue(settings?.x1FieldId ?? '');
  const x2Field = getFieldByValue(settings?.x2FieldId ?? '');
  const aggregateField = getFieldByValue(settings?.yFieldId ?? '');
  const groupBy: GroupBy[] = [];
  if (settings?.aggregationType) {
    if (x1Field) {
      groupBy.push({
        field: x1Field,
        datePrecision: settings.x1FieldDatePrecision,
      });
    }
    if (x2Field) {
      groupBy.push({ field: x2Field });
    }
  }

  const reportDataDefinition: ReportingDataInput | undefined =
    selectedCustomDatasource
      ? {
          dataSources: selectedCustomDatasource.Datasources,
          limit: pageSize,
          filters: selectedCustomDatasource.Filters,
          fields: settings?.aggregationType
            ? []
            : (selectedCustomDatasource?.Fields ?? []),
          groupBy,
          offset: pageIndex * pageSize,
          aggregateType: settings?.aggregationType,
          aggregateField,
        }
      : undefined;

  const { data, loading, error } = useGetReportingDataQuery({
    variables: reportDataDefinition
      ? { Input: reportDataDefinition }
      : undefined,
    skip: !validSettings || !reportDataDefinition,
    fetchPolicy: 'no-cache',
  });

  const x1FieldDefinition =
    x1Field && selectedCustomDatasource && customAttributeSchemaLookup
      ? getFieldDefinition(
          selectedCustomDatasource.Datasources,
          x1Field,
          customAttributeSchemaLookup
        )
      : null;
  const x2FieldDefinition =
    x2Field && selectedCustomDatasource && customAttributeSchemaLookup
      ? getFieldDefinition(
          selectedCustomDatasource.Datasources,
          x2Field,
          customAttributeSchemaLookup
        )
      : null;

  const xAxisDataType: DataType = x1FieldDefinition?.dataType ?? 'text';
  const seriesData = useMapReportDataToSeries({
    x1FieldDefinition,
    x2FieldDefinition,
    reportingData: (data?.reportingData as ReportField[][]) ?? [],
    x1GroupByDatePrecision: settings?.x1FieldDatePrecision ?? null,
    // Don't currently support date precision on x2
    x2GroupByDatePrecision: null,
  });

  const total = _.sumBy(
    seriesData.flatMap((s) => s.data),
    (val) => val.y as number
  );

  return (
    <div className={'h-full flex flex-col gap-2'}>
      {showSettingsModal && (
        <CustomDataSourceWidgetSettingsModel
          onDismiss={function (): void {
            setShowSettingsModal(false);
          }}
          onSave={async (data) => setSettings(data)}
        />
      )}
      {!settings && !validSettings && (
        <ConfigureWidgetPanel
          onConfigureClick={() => setShowSettingsModal(true)}
        />
      )}

      {error && (
        <Alert header={'Error'} type={'error'}>
          {t('data_request_failure_message')}
        </Alert>
      )}

      {!reportDataDefinition && validSettings && settings && (
        <Alert header={'Error'} type={'warning'}>
          {t('datasource_not_found_message')}
        </Alert>
      )}

      {!error &&
        validSettings &&
        settings &&
        customAttributeSchemaLookup &&
        selectedCustomDatasource && (
          <WidgetChart
            customAttributeSchemaLookup={customAttributeSchemaLookup}
            reportDataDefinition={selectedCustomDatasource}
            onPageChangeClick={(e) => {
              setPageIndex(e.requestedPageIndex);
            }}
            onCategoryClick={({ value }) => {
              if (selectedCustomDatasource && x1Field && x1FieldDefinition) {
                navigate(
                  customDatasourceUrl(selectedCustomDatasource.Id, {
                    filtering: getFilterPropertyForCategory({
                      x1Field,
                      value,
                      x1FieldDatePrecision: settings.x1FieldDatePrecision,
                      x1FieldType: x1FieldDefinition?.dataType,
                    }),
                  })
                );
              }
            }}
            reportData={(data?.reportingData as ReportField[][]) ?? []}
            chartType={settings.chartType}
            seriesData={seriesData}
            pageSize={pageSize}
            currentPageIndex={pageIndex}
            loading={loading}
            xAxisDataType={xAxisDataType}
            xAxisDatePrecision={settings.x1FieldDatePrecision ?? null}
            innerMetricValue={total?.toString()}
          />
        )}
    </div>
  );
};

CustomDataSourceWidget.displayName = 'CustomDataSourceWidget';
