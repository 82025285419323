import i18n from '@risksmart-app/i18n/i18n';

import {
  GetAcceptancesDocument,
  Parent_Type_Enum,
} from '@/generated/graphql.typed';
import { acceptanceRegisterUrl } from '@/utils/urls';

import { useGetCollectionTableProps } from '../../../acceptances/config';
import {
  dateRangeFilter,
  departmentsFilter,
  tagsFilter,
} from '../../Gigawidget/util/filterHelpers';
import type { DashboardFilter } from '../../useDashboardStore';
import { convertDateRangeValues } from '../../widgets/filterHelpers';
import { createDataSource } from '../createDataSource';
import { defaultClickthroughFilterWithUnrated } from '../dataSourceHelpers';

export default createDataSource({
  hasAccess: () => true,
  documentNode: GetAcceptancesDocument,
  useDefaultVariables: () => ({ where: {} }),
  parentTypes: [Parent_Type_Enum.Acceptance],
  useTablePropsHook: (data) =>
    useGetCollectionTableProps(
      data?.acceptance,
      data?.form_configuration[0]?.customAttributeSchema
    ),
  entityNamePlural: 'acceptance_other',
  entityNameSingular: 'acceptance_one',
  fields: 'acceptances.fields',
  clickThroughUrl: (filters) => acceptanceRegisterUrl(filters),
  dashboardFilterConfig: {
    tagsFilter: (tags) => ({
      where: { parents: { risk: { tags: tagsFilter(tags) } } },
    }),
    departmentsFilter: (departments) => ({
      where: {
        parents: { risk: { departments: departmentsFilter(departments) } },
      },
    }),
    dateFilter: (dateRange, precision) => ({
      where: {
        DateAcceptedFrom: dateRangeFilter(dateRange, precision, 'gte'),
        DateAcceptedTo: dateRangeFilter(dateRange, precision, 'lte'),
      },
    }),
    dateClickthroughFilter: (filter: DashboardFilter['dateRange']) => {
      const { startDate, endDate } = convertDateRangeValues(filter);

      return startDate && endDate
        ? [
            {
              propertyKey: 'DateAcceptedFrom',
              operator: '>=',
              value: startDate.toISOString(),
            } as const,
            {
              propertyKey: 'DateAcceptedTo',
              operator: '<=',
              value: endDate.toISOString(),
            } as const,
          ]
        : [];
    },
  },
  categoryGetters: [
    {
      id: 'status',
      name: () => i18n.t('acceptances.columns.status'),
      categoryGetter: (item) => ({
        key: item.Status,
        label: item.StatusLabelled,
      }),
      clickthroughFilter:
        defaultClickthroughFilterWithUnrated('StatusLabelled'),
      ratingColourKey: 'acceptance_status',
    },
  ],
});
