import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetWizardByIdQuery } from '@/generated/graphql';

import { useWizardStore } from '../store/useWizardStore';
import type { Risk, Step } from '../types';
import { StepStatus } from '../types';

export const useInitiateWizard = (risk: Risk) => {
  const {
    setCurrentStep,
    setSteps,
    setRisk,
    addActiveWizard,
    risk: activeRisk,
    setAssessmentId,
  } = useWizardStore();
  const { t } = useTranslation('common', { keyPrefix: 'wizard' });

  const initiateSteps = (currentStep: number) =>
    t('steps')?.map((step: Step, i) => {
      if (i < currentStep) {
        step.status = StepStatus.Complete;

        return step;
      }
      if (i === currentStep) {
        step.status = StepStatus.InProgress;

        return step;
      }

      step.status = StepStatus.ToDO;

      return step;
    });

  const { data, loading } = useGetWizardByIdQuery({
    variables: {
      RiskId: risk.riskId,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!loading && activeRisk.riskId !== risk.riskId && risk.title) {
      setRisk(risk);
      setCurrentStep(data?.wizard[0]?.CurrentStep ?? 0);
      setAssessmentId(data?.wizard[0]?.AssessmentId ?? '');
      setSteps(initiateSteps(data?.wizard[0]?.CurrentStep ?? 0));
      if (data?.wizard[0]) {
        addActiveWizard(risk.riskId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, risk.title]);
};
