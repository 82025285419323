import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalForm } from '@/components/Form/Form/ModalForm';
import {
  Parent_Type_Enum,
  useGetAssessmentsQuery,
  useInsertWizardMutation,
} from '@/generated/graphql';
import { handleError } from '@/utils/errorUtils';

import { LinkAssessmentForm } from '../forms/LinkAssessmentForm';
import type { LinkAssessmentFormFields } from '../forms/LinkAssessmentFormSchema';
import { LinkAssessmentFormSchema } from '../forms/LinkAssessmentFormSchema';
import { useNavigateToInProgress } from '../hooks/useNavigateToInProgress';
import { useWizardStore } from '../store/useWizardStore';

type LinkAssessmentModalProps = {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  setIsCreateAssessmentModalVisible: Dispatch<SetStateAction<boolean>>;
  basePath: string;
  riskId: string;
};

export const LinkAssessmentModal: FC<LinkAssessmentModalProps> = ({
  isVisible,
  setIsVisible,
  setIsCreateAssessmentModalVisible,
  basePath,
  riskId,
}) => {
  const { addNotification } = useNotifications();
  const { t } = useTranslation(['common'], { keyPrefix: 'wizard' });
  const { navigateToInProgress } = useNavigateToInProgress();
  const { addActiveWizard, setAssessmentId } = useWizardStore();

  const [insertWizardMutation] = useInsertWizardMutation();

  const { data } = useGetAssessmentsQuery({
    onError: (error) => {
      handleError(error);
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
      setIsVisible(false);
    },
  });

  const onSave = async (value: LinkAssessmentFormFields) => {
    await insertWizardMutation({
      onCompleted: () => {
        addActiveWizard(riskId);
        navigateToInProgress(basePath);
        setAssessmentId(value.AssessmentId);
      },
      variables: {
        object: { RiskId: riskId, AssessmentId: value.AssessmentId },
      },
      onError: (error) => {
        handleError(error);
        addNotification({
          type: 'error',
          content: <>{error.message}</>,
        });
      },
    });
  };

  const onDismiss = async () => {
    setIsVisible(false);
  };

  const createAssessment = async () => {
    setIsVisible(false);
    setIsCreateAssessmentModalVisible(true);
  };

  return (
    <ModalForm
      i18n={{
        entity_name: 'Assessment',
        edit_modal_title: t('forms.linkModalTitle'),
        create_modal_title: t('forms.linkModalTitle'),
      }}
      testId={'link-assessment-form-settings-button'}
      onSave={onSave}
      onDismiss={onDismiss}
      defaultValues={{ AssessmentId: '' }}
      schema={LinkAssessmentFormSchema}
      formId={'link-assessment-form'}
      visible={isVisible}
      secondaryActions={[
        { label: t('forms.createAssessmentButton'), action: createAssessment },
      ]}
      parentType={Parent_Type_Enum.Assessment}
    >
      <LinkAssessmentForm
        assessmentOptions={
          data?.assessment.map((assessment) => {
            return {
              id: assessment.Id,
              value: assessment.Id,
              label: assessment.Title,
              description: assessment.Summary,
            };
          }) ?? []
        }
      ></LinkAssessmentForm>
    </ModalForm>
  );
};
