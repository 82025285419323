import type { FilteringProperty } from '@cloudscape-design/components/property-filter/interfaces';
import type { RatingOption } from 'src/ratings/ratings';

import SimpleRatingBadge from '@/components/SimpleRatingBadge';

import SelectFilter from '../filters/SelectFilter';
import { nullDataChartLabel } from '../nullData';
import type { AllowedField } from '../utils';
import type { CellInfo, Helpers, ReportFieldType } from './types';

const getRating = ({
  fieldData,
  fieldDef,
  helpers,
}: CellInfo): RatingOption | undefined => {
  if (fieldDef.displayType !== 'rating') {
    throw new Error('rating filed type used out of context');
  }

  return helpers.getRatingByValue(
    fieldDef.ratingKey,
    fieldData.value as number | string
  );
};

export const rating: ReportFieldType = {
  getChartColor(cellData) {
    const rating = getRating(cellData);

    return rating?.color;
  },
  getChartLabel(cellData) {
    const rating = getRating(cellData);

    return rating?.label ?? nullDataChartLabel();
  },
  cell: function (cellData) {
    const rating = getRating(cellData);

    return <SimpleRatingBadge rating={rating} />;
  },
  propertyConfig: function (
    field: AllowedField,
    { getRatingByValue, getRatingOptions }: Helpers
  ): FilteringProperty {
    const fieldDef = field.fieldDef;
    if (fieldDef.displayType !== 'rating') {
      throw new Error('rating filed type used out of context');
    }

    return {
      key: field.value,
      groupValuesLabel: '',
      propertyLabel: field.label,
      operators: [
        {
          operator: '=',
          format: (value) => {
            const rating = getRatingByValue(fieldDef.ratingKey, value);

            return rating?.label ?? '';
          },
          form: (props) => {
            return (
              <SelectFilter
                {...props}
                options={getRatingOptions(fieldDef.ratingKey).map((o) => ({
                  value: (o.value as unknown as string) ?? undefined,
                  label: o.label,
                }))}
              />
            );
          },
        },
      ],
    };
  },
};
