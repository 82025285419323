import type { TableProps } from '@cloudscape-design/components/table';
import Table from '@risksmart-app/components/Table';
import type { FC, ReactNode } from 'react';

import type { GetReportingDataQuery } from '@/generated/graphql';

import type { TypedCustomDatasource } from '../types';
import { useCustomDatasourceHelpers } from '../useCustomDatasourceHelpers';
import { displayTypes } from './displayTypes';
import Pager from './Pager';
import type { CustomAttributeSchemaLookup } from './types';
import { getCustomDatasourceFields } from './utils';

export type Props = {
  variant?: TableProps.Variant;
  definition: Pick<TypedCustomDatasource, 'Datasources' | 'Fields'>;
  items: GetReportingDataQuery['reportingData'];
  onPageChangeClick?: (e: { requestedPageIndex: number }) => void;
  currentPageIndex: number;
  pageSize: number;
  loading: boolean;
  customAttributeSchemaLookup: CustomAttributeSchemaLookup;
  filter?: ReactNode;
};

const ReportTable: FC<Props> = ({
  definition,
  items,
  onPageChangeClick,
  currentPageIndex,
  pageSize,
  loading,
  customAttributeSchemaLookup,
  variant,
  filter,
}) => {
  const helpers = useCustomDatasourceHelpers();
  const fields = getCustomDatasourceFields(
    definition,
    customAttributeSchemaLookup
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columnDefinitions: readonly TableProps.ColumnDefinition<any>[] =
    fields.map((field, index) => {
      return {
        header: field?.label ?? '-',
        cell: (item) => {
          const fieldDef = field?.fieldDef;
          if (!fieldDef) {
            throw new Error('Missing field def');
          }

          return displayTypes[fieldDef.displayType].cell({
            fieldDef,
            fieldData: item[index],
            helpers,
          });
        },
      };
    }) ?? [];

  return (
    <Table
      filter={filter}
      loading={loading}
      variant={variant}
      pagination={
        onPageChangeClick && (
          <Pager
            loading={loading}
            currentPageIndex={currentPageIndex}
            pageSize={pageSize}
            currentPageSize={items?.length ?? 0}
            onPageChangeClick={onPageChangeClick}
          />
        )
      }
      items={items ?? []}
      columnDefinitions={columnDefinitions}
      empty={'No records found'}
    />
  );
};

export default ReportTable;
