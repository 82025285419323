import type { DateRangePickerProps } from '@cloudscape-design/components/date-range-picker';

interface DashboardFilter {
  departments: Array<string>;
  tags: Array<string>;
  dateRange: DateRangePickerProps.Value | null;
}

export const defaultDashboardFilter: DashboardFilter = {
  departments: [],
  tags: [],
  dateRange: null,
};
