import Alert from '@cloudscape-design/components/alert';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { PageNotFound } from '@risksmart-app/components/errors/errors';
import {
  arrayToCsv,
  downloadBlob,
} from '@risksmart-app/components/File/fileUtils';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import _ from 'lodash';
import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from 'src/layouts/PageLayout';
import { useCustomAttributeLookup } from 'src/pages/dashboards/CustomDataSourceWidget/useCustomAttributeLookup';
import { Permission } from 'src/rbac/Permission';

import type { GetReportingDataQueryVariables } from '@/generated/graphql';
import {
  useGetCustomDatasourceByIdQuery,
  useGetReportingDataLazyQuery,
  useGetReportingDataQuery,
} from '@/generated/graphql';
import { emptyFilterQuery } from '@/utils/collectionUtils';
import { useFiltersFromUrlHash } from '@/utils/table/hooks/useFiltersFromUrlHash';
import { editCustomDatasourceUrl } from '@/utils/urls';

import type { TypedCustomDatasource } from '../types';
import CustomDatasourcePropertyFilter from '../update/CustomDatasourcePropertyFilter';
import ReportTable from '../update/ReportTable';
import { getCustomDatasourceFields } from '../update/utils';
import { combinedFilters } from './combineFilters';
import { useCustomDatasourceExporterMapper } from './customDatasourceExporter';

const Page: FC = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 20;
  const customDataSourceId = useGetGuidParam('customDatasourceId');
  const { data: customDatasourceData, loading } =
    useGetCustomDatasourceByIdQuery({
      variables: { Id: customDataSourceId },
      fetchPolicy: 'no-cache',
    });
  const customDatasource: null | TypedCustomDatasource | undefined =
    customDatasourceData?.custom_datasource_by_pk;
  if (!loading && !customDatasource) {
    throw new PageNotFound(
      `Custom datasource with id ${customDataSourceId} not found`
    );
  }

  const { propertyFilter, setPropertyFilter } = useFiltersFromUrlHash({});

  const { t } = useTranslation(['common'], { keyPrefix: 'customDatasources' });
  const { t: rt } = useTranslation(['common']);
  const { customAttributeSchemaLookup } = useCustomAttributeLookup();

  const formatForExport = useCustomDatasourceExporterMapper({
    customAttributeSchemaLookup,
    customDatasource,
  });
  const getVariables = (
    customDatasource: null | TypedCustomDatasource | undefined,
    paging: { offset: number; limit: number }
  ): GetReportingDataQueryVariables | undefined => {
    if (!customDatasource) {
      return undefined;
    }

    return {
      Input: {
        filters: combinedFilters(customDatasource, propertyFilter),
        dataSources: customDatasource.Datasources,
        fields: customDatasource.Fields ?? [],
        ...paging,
      },
    };
  };

  const exportToCsv = async () => {
    const results = await getReportData({
      variables: getVariables(customDatasource, { offset: 0, limit: 100000 }),
    });

    const formatted = formatForExport(results.data?.reportingData);

    const csv = arrayToCsv(formatted);
    const blob = new Blob(['\ufeff', csv], {
      type: 'text/csv;charset=utf-8',
    });

    downloadBlob(`export.csv`, blob);
  };

  // TODO: potentially replace this with a new api that acceptances a customDatasourceId
  // this will avoid the need to call useGetCustomDatasourceByIdQuery first, and could offer bet security
  // if we want to look down the end point that supports any kind of query
  const {
    data: reportData,
    loading: reportDataLoading,
    error: reportDataError,
  } = useGetReportingDataQuery({
    variables: getVariables(customDatasource, {
      offset: pageIndex * pageSize,
      limit: pageSize,
    }),
    fetchPolicy: 'no-cache',
    skip: !customDatasource,
  });
  const [getReportData, getReportDataResult] = useGetReportingDataLazyQuery({
    fetchPolicy: 'no-cache',
  });

  return (
    <PageLayout
      title={customDatasource?.Title ?? t('create_title')}
      actions={
        customDatasource && (
          <SpaceBetween direction={'horizontal'} size={'xxs'}>
            <Button
              iconName={'download'}
              disabled={getReportDataResult.loading || loading}
              onClick={exportToCsv}
            >
              {rt('export')}
            </Button>
            <Permission permission={'update:custom_datasource'}>
              <Button
                variant={'normal'}
                formAction={'none'}
                href={
                  customDataSourceId &&
                  editCustomDatasourceUrl(customDataSourceId)
                }
              >
                {t('edit_button')}
              </Button>
            </Permission>
          </SpaceBetween>
        )
      }
    >
      {reportDataError && !reportDataLoading && (
        <Alert type={'error'}>{t('data_request_failure_message')}</Alert>
      )}
      {customAttributeSchemaLookup && customDatasource && (
        <ReportTable
          filter={
            customDatasource && (
              <CustomDatasourcePropertyFilter
                query={propertyFilter ?? emptyFilterQuery}
                onChange={(e) => {
                  setPropertyFilter(e);
                }}
                allFields={getCustomDatasourceFields(
                  customDatasource,
                  customAttributeSchemaLookup
                )}
                datasources={customDatasource.Datasources}
              />
            )
          }
          customAttributeSchemaLookup={customAttributeSchemaLookup}
          loading={reportDataLoading}
          pageSize={pageSize}
          currentPageIndex={pageIndex}
          onPageChangeClick={({ requestedPageIndex }) => {
            setPageIndex(requestedPageIndex);
          }}
          definition={customDatasource}
          items={reportData?.reportingData ?? []}
        />
      )}
    </PageLayout>
  );
};

export default Page;
