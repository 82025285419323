import { t } from 'i18next';

import { ParentTypeEnum } from '../../../generated/graphql';
import { DataSourceType } from '../schema';
import { getAuditColumns } from './shared';
import { SharedDataset } from './types';

export const getTestResults = () =>
  ({
    label: 'Test Results',
    parentTypes: new Set<DataSourceType>(['controls']),
    customAttributeFormConfigurationParentTypes: [ParentTypeEnum.TestResult],
    fields: {
      ...getAuditColumns(),
      id: { dataType: 'guid', displayType: 'text', label: t('columns.guid') },
      sequentialId: {
        dataType: 'number',
        displayType: 'number',
        label: t('columns.id'),
        prefix: 'TR-',
      },
      title: { dataType: 'text', displayType: 'text', label: t('titleField') },
      designEffectiveness: {
        dataType: 'number',
        displayType: 'rating',
        ratingKey: 'design_effectiveness',
        label: t('testResults.columns.design_effectiveness'),
      },
      performanceEffectiveness: {
        dataType: 'number',
        displayType: 'rating',
        ratingKey: 'performance_effectiveness',
        label: t('testResults.columns.performance_effectiveness'),
      },
      testResult: {
        dataType: 'number',
        displayType: 'rating',
        ratingKey: 'effectiveness',
        label: t('testResults.columns.overall_effectiveness'),
      },
      details: {
        dataType: 'text',
        displayType: 'text',
        label: t('controlTestDetails'),
      },
      performedById: {
        dataType: 'text',
        displayType: 'text',
        label: 'Performed by Id',
      },
      performedByFriendlyName: {
        dataType: 'text',
        displayType: 'text',
        label: t('testResults.columns.submitter'),
      },
      testDate: {
        dataType: 'date',
        displayType: 'date',
        label: t('testResults.columns.date'),
      },
      typeType: {
        dataType: 'text',
        displayType: 'commonLookup',
        i18nKey: 'testTypes',
        label: t('testResults.columns.test_type'),
      },
    },
  }) satisfies SharedDataset;
