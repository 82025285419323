import type { LocalStorageKeys } from '@risksmart-app/components/hooks/useLocalStorage';

import { Parent_Type_Enum } from '@/generated/graphql';
import {
  addAssessmentActivityUrl,
  addComplianceMonitoringAssessmentActivityUrl,
  addInternalAuditActivityUrl,
  assessmentActivitiesDetailsUrl,
  assessmentActivitiesRegisterUrl,
  assessmentDetailsUrl,
  assessmentLinkedItemsUrl,
  assessmentRegisterUrl,
  assessmentResultsAddUrl,
  assessmentResultsEditUrl,
  assessmentResultsUrl,
  complianceMonitoringAssessmentActivitiesDetailsUrl,
  complianceMonitoringAssessmentActivitiesRegisterUrl,
  complianceMonitoringAssessmentDetailsUrl,
  complianceMonitoringAssessmentLinkedItemsUrl,
  complianceMonitoringAssessmentRegisterUrl,
  complianceMonitoringAssessmentResultsAddUrl,
  complianceMonitoringAssessmentResultsEditUrl,
  complianceMonitoringAssessmentResultsUrl,
  internalAuditReportActivitiesDetailsUrl,
  internalAuditReportActivitiesRegisterUrl,
  internalAuditReportDetailsUrl,
  internalAuditReportLinkedItemsUrl,
  internalAuditReportRegisterUrl,
  internalAuditReportResultsAddUrl,
  internalAuditReportResultsEditUrl,
  internalAuditReportResultsUrl,
} from '@/utils/urls';

import type { RatingKeys } from '../../ratings/ratings';
import type { ObjectAccess } from '../../rbac/Permission';

interface AssessmentTypeConfig {
  taxonomyKey:
    | 'assessments'
    | 'complianceMonitoringAssessment'
    | 'internalAuditReports';
  permissions: {
    update: ObjectAccess;
    read: ObjectAccess;
    delete: ObjectAccess;
  };
  routing: {
    detailsUrl: (id: string) => string;
    resultsRegisterUrl: (id: string) => string;
    resultsAddUrl: (id: string) => string;
    resultsEditUrl: (
      assessmentId: string,
      assessmentResultId: string
    ) => string;
    registerUrl: () => string;
    activityAddUrl: (id: string) => string;
    activityRegisterUrl: (id: string) => string;
    activityEditUrl: (id: string, activityId: string) => string;
    linkedItemsUrl: (id: string) => string;
  };
  parentType: Parent_Type_Enum;
  registerStorageKey: LocalStorageKeys;
  outcomeRatingKey: RatingKeys;
}

export type AssessmentTypeEnum =
  | 'compliance_monitoring_assessment'
  | 'internal_audit_report'
  | 'rating';

export const useAssessmentTypeConfig = (
  assessmentMode: AssessmentTypeEnum
): AssessmentTypeConfig => {
  switch (assessmentMode) {
    case 'rating': {
      return {
        permissions: {
          update: 'update:assessment',
          read: 'read:assessment',
          delete: 'delete:assessment',
        },
        taxonomyKey: 'assessments',
        routing: {
          detailsUrl: assessmentDetailsUrl,
          resultsRegisterUrl: assessmentResultsUrl,
          resultsAddUrl: assessmentResultsAddUrl,
          resultsEditUrl: assessmentResultsEditUrl,
          registerUrl: assessmentRegisterUrl,
          activityAddUrl: addAssessmentActivityUrl,
          activityEditUrl: assessmentActivitiesDetailsUrl,
          activityRegisterUrl: assessmentActivitiesRegisterUrl,
          linkedItemsUrl: assessmentLinkedItemsUrl,
        },
        parentType: Parent_Type_Enum.Assessment,
        registerStorageKey: 'AssessmentRegister-Preferences',
        outcomeRatingKey: 'assessment_outcome',
      };
    }
    case 'compliance_monitoring_assessment': {
      return {
        taxonomyKey: 'complianceMonitoringAssessment',
        permissions: {
          update: 'update:compliance_monitoring_assessment',
          read: 'read:compliance_monitoring_assessment',
          delete: 'delete:compliance_monitoring_assessment',
        },
        routing: {
          detailsUrl: complianceMonitoringAssessmentDetailsUrl,
          resultsRegisterUrl: complianceMonitoringAssessmentResultsUrl,
          resultsEditUrl: complianceMonitoringAssessmentResultsEditUrl,
          resultsAddUrl: complianceMonitoringAssessmentResultsAddUrl,
          registerUrl: complianceMonitoringAssessmentRegisterUrl,
          activityAddUrl: addComplianceMonitoringAssessmentActivityUrl,
          activityEditUrl: complianceMonitoringAssessmentActivitiesDetailsUrl,
          activityRegisterUrl:
            complianceMonitoringAssessmentActivitiesRegisterUrl,
          linkedItemsUrl: complianceMonitoringAssessmentLinkedItemsUrl,
        },
        parentType: Parent_Type_Enum.ComplianceMonitoringAssessment,
        registerStorageKey:
          'ComplianceMonitoringAssessmentRegister-Preferences',
        outcomeRatingKey: 'compliance_monitoring_assessment_outcome',
      };
    }
    case 'internal_audit_report': {
      return {
        permissions: {
          update: 'update:internal_audit_report',
          read: 'read:internal_audit_report',
          delete: 'delete:internal_audit_report',
        },
        taxonomyKey: 'internalAuditReports',
        routing: {
          detailsUrl: internalAuditReportDetailsUrl,
          resultsRegisterUrl: internalAuditReportResultsUrl,
          resultsEditUrl: internalAuditReportResultsEditUrl,
          resultsAddUrl: internalAuditReportResultsAddUrl,
          registerUrl: internalAuditReportRegisterUrl,
          activityAddUrl: addInternalAuditActivityUrl,
          activityEditUrl: internalAuditReportActivitiesDetailsUrl,
          activityRegisterUrl: internalAuditReportActivitiesRegisterUrl,
          linkedItemsUrl: internalAuditReportLinkedItemsUrl,
        },
        parentType: Parent_Type_Enum.InternalAuditReport,
        registerStorageKey: 'InternalAuditReportRegister-Preferences',
        outcomeRatingKey: 'internal_audit_report_outcome',
      };
    }
  }
};
