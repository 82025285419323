import type { PieChartProps } from '@cloudscape-design/components/pie-chart';
import PieChart from '@cloudscape-design/components/pie-chart';
import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { genericCategoricalPalette } from '@/utils/colours';

import { NoWidgetData } from '../../widgets/NoWidgetData';
import styles from './styles.module.scss';

export type Props = {
  loading: boolean;
  data: { x: unknown; y: unknown; color?: string; label?: string }[];
  donut: boolean;
  innerMetricValue?: string;
  onSegmentClick?: (data: SegmentData) => void;
};
export type SegmentData = { value: unknown };

type PieChartData = PieChartProps.Datum & {
  x: unknown;
};

export const WidgetPieChart: FC<Props> = ({
  loading,
  data,
  donut,
  innerMetricValue,
  onSegmentClick,
}) => {
  const [highlightedSegment, setHighlightedSegment] =
    useState<null | PieChartData>(null);
  const { t } = useTranslation(['common'], {
    keyPrefix: 'dashboard',
  });
  const pieData: PieChartData[] = data?.map((d, i) => ({
    title: d.label ?? String(d.x),
    value: d.y as number,
    x: d.x,
    color: d.color ?? genericCategoricalPalette(i),
  }));

  return (
    <div
      className={styles.pieChart}
      onClick={() =>
        highlightedSegment && onSegmentClick?.({ value: highlightedSegment.x })
      }
    >
      <PieChart<PieChartData>
        statusType={loading ? 'loading' : 'finished'}
        hideFilter={true}
        onHighlightChange={(e) =>
          setHighlightedSegment(e.detail.highlightedSegment)
        }
        variant={donut ? 'donut' : 'pie'}
        fitHeight={true}
        empty={<NoWidgetData />}
        hideLegend={true}
        data={pieData}
        innerMetricValue={innerMetricValue}
        detailPopoverContent={(datum, sum) => [
          { key: t('count'), value: datum.value },
          {
            key: t('percentage'),
            value: `${((datum.value / sum) * 100).toFixed(0)}%`,
          },
        ]}
      />
    </div>
  );
};
