import type { DateRangePickerProps } from '@cloudscape-design/components/date-range-picker';
import type { LocalStorageKeys } from '@risksmart-app/components/hooks/useLocalStorage';
import { useEffect } from 'react';
import { defaultDashboardFilter } from 'src/context/defaultDashboardFilter';
import { processWidgets } from 'src/context/processWidgets';
import { create } from 'zustand';

import { useOrgScopedLocalStorage } from '@/hooks/useOrgScopedLocalStorage';

import { defaultLayout } from './defaultLayout';
import type { StoredWidgetPlacement } from './types';

export interface DashboardFilter {
  departments: string[];
  tags: string[];
  dateRange: DateRangePickerProps.Value | null;
}

export type State = {
  id?: string;
  filters: DashboardFilter;
  widgets: StoredWidgetPlacement[];
};

type Action = {
  setFilters: (filters: DashboardFilter) => void;
  setWidgets: (widgets: StoredWidgetPlacement[]) => void;
  setId: (id: string) => void;
  setDashboardPreferences: (dashboardPreferences: State) => void;
};

const STORAGE_KEY: LocalStorageKeys = 'Dashboard-PreferencesV3';

const useInternalDashboardStore = create<State & Action>()((set) => ({
  filters: defaultDashboardFilter,
  widgets: [],
  setFilters: (filters: DashboardFilter) => set({ filters }),
  setWidgets: (widgets: StoredWidgetPlacement[]) =>
    set({ widgets: processWidgets(widgets) }),
  setId: (id: string) => set({ id }),
  setDashboardPreferences: (dashboardPreferences: State) =>
    set(dashboardPreferences),
}));

export const useDashboardStore = () => {
  const [storedValue, setValue] = useOrgScopedLocalStorage<State>(
    { filters: defaultDashboardFilter, widgets: defaultLayout },
    {
      localStorageKey: STORAGE_KEY,
    }
  );

  const {
    filters: storeFilters,
    setFilters: setStoreFilters,
    widgets: storeWidgets,
    setWidgets: setStoreWidgets,
    id: storeId,
    setId: setStoreId,
    setDashboardPreferences: setStoreDashboardPreferences,
  } = useInternalDashboardStore();

  const setFilters = (filters: DashboardFilter) => {
    setStoreFilters(filters);
    setValue({ filters, widgets: storeWidgets, id: storeId });
  };
  const setWidgets = (widgets: StoredWidgetPlacement[]) => {
    setStoreWidgets(widgets);
    setValue({ widgets, filters: storeFilters, id: storeId });
  };
  const setId = (id: string) => {
    setStoreId(id);
    setValue({
      id,
      filters: storeFilters,
      widgets: storeWidgets,
    });
  };
  const setDashboardPreferences = (dashboardPreferences: State) => {
    setStoreDashboardPreferences(dashboardPreferences);
    setValue({ id: storeId, ...dashboardPreferences });
  };

  // If empty store then set initial values
  useEffect(() => {
    setDashboardPreferences(storedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    filters: storeFilters,
    widgets: processWidgets(storeWidgets),
    id: storeId,
    setFilters,
    setWidgets,
    setId,
    setDashboardPreferences,
  };
};
