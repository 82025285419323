import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import { useTools } from '@risksmart-app/components/Tools/useTools';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Assessment_Activity_Status_Enum,
  Assessment_Activity_Type_Enum,
  useDeleteWizardMutation,
  useInsertAssessmentActivityWithLinkedItemsMutation,
} from '@/generated/graphql';
import { handleError } from '@/utils/errorUtils';
import { evictField } from '@/utils/graphqlUtils';

import { useWizardStore } from '../store/useWizardStore';
import { StepStatus } from '../types';

export const useCloseWizard = (riskId: string) => {
  const {
    setCurrentStep,
    steps,
    setSteps,
    removeActiveWizard,
    setRisk,
    risk,
    assessmentId,
  } = useWizardStore();
  const [_, setToolsContent] = useTools();
  const { t } = useTranslation('common', { keyPrefix: 'wizard' });
  const { user } = useRisksmartUser();
  const { addNotification } = useNotifications();

  const [loading, setIsLoading] = useState(false);

  const [deleteWizardMutation] = useDeleteWizardMutation({
    variables: {
      RiskId: riskId,
    },
  });

  const [insertAssessmentActivityWithLinkedItemsMutation] =
    useInsertAssessmentActivityWithLinkedItemsMutation({
      update: (cache) => {
        evictField(cache, 'assessment');
        evictField(cache, 'compliance_monitoring_assessment');
        evictField(cache, 'internal_audit_entity');
        evictField(cache, 'internal_audit_report');
        evictField(cache, 'assessment_activity');
      },
    });

  return {
    closeWizard: async (isLastStep = false) => {
      const inProgressStep = steps?.findIndex(
        (step) => step.status === StepStatus.InProgress
      );
      if (isLastStep) {
        setIsLoading(true);
        await deleteWizardMutation({
          onError: (error) => {
            handleError(error);
            addNotification({
              type: 'error',
              content: <>{error.message}</>,
            });
          },
        });
        await insertAssessmentActivityWithLinkedItemsMutation({
          variables: {
            Title: `${t('wizardName')} - ${risk.title}`,
            Status: Assessment_Activity_Status_Enum.Complete,
            ActivityType: Assessment_Activity_Type_Enum.Task,
            Summary: `${t('wizardName')} - ${risk.title}`,
            LinkedItemIds: [riskId],
            ParentId: assessmentId,
            AssignedUser: user?.userId,
            CompletionDate: new Date().toISOString(),
            IsRCSA: true,
          },
          onError: (error) => {
            handleError(error);
            addNotification({
              type: 'error',
              content: <>{error.message}</>,
            });
          },
        });
        setSteps(t('steps'));
        setCurrentStep(0);
        removeActiveWizard(riskId);
        setRisk({ riskId: '', title: '' });
        setIsLoading(false);
      } else {
        setCurrentStep(inProgressStep);
      }

      setToolsContent(undefined);
    },
    loading,
  };
};
