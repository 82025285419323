import { getSharedDatasets } from '@risksmart-app/shared/reporting/datasets';
import type {
  FieldDefinition,
  SharedDataset,
} from '@risksmart-app/shared/reporting/datasets/types';
import { aggregateTypeSupportedDataTypes } from '@risksmart-app/shared/reporting/dataTypes';
import type {
  AggregateType,
  DataSourceType,
} from '@risksmart-app/shared/reporting/schema';

import { getCustomAttributeRenderProps } from '@/components/Form/CustomAttributes/CustomAttributeCollections';
import { FieldType } from '@/components/Form/CustomAttributes/EditFields/NewFieldSchema';
import type { FieldRendererProps } from '@/components/Form/CustomAttributes/Renderers/CollectionLayouts/TableCollectionRenderers';
import type { Parent_Type_Enum } from '@/generated/graphql';

import type { TypedCustomDatasource } from '../types';
import type { TreeDataSource } from './definitionSchema';
import type { CustomAttributeSchemaLookup } from './types';

const customAttributeFieldIdPrefix = 'custom/';

export type RelatedDataSource = { type: DataSourceType; parentIndex?: number };

/**
 * Converts a tree of data sources into a list, with joins based on parent index
 * @param root
 * @returns
 */
export const getFlattenedDataSources = (
  root: TreeDataSource
): RelatedDataSource[] => {
  const dataSources: RelatedDataSource[] = [];

  const flattenDataSourceTree = (
    datasource: TreeDataSource,
    parentIndex: number | undefined
  ) => {
    if (!datasource.type) {
      return;
    }
    dataSources.push({
      type: datasource.type,
      parentIndex,
    });
    const childParentIndex = parentIndex == undefined ? 0 : ++parentIndex;
    datasource.children.forEach((childDataSource) => {
      flattenDataSourceTree(childDataSource, childParentIndex);
    });
  };
  flattenDataSourceTree(root, undefined);

  return dataSources;
};

/**
 * Converts a list of data sources into a tree as required by the data source component
 * @param dataSources
 * @returns
 */
export const getTreeDataSources = (
  dataSources: RelatedDataSource[]
): TreeDataSource => {
  const dsIndexes = dataSources.map((ds, index) => ({ ds, index }));

  const mapToTreeDataSource = (dsIndex: number | undefined) => {
    const matchingDs = dsIndexes.find(({ index }) => index === dsIndex);
    if (!matchingDs) {
      throw new Error(`Missing datasource at index ${dsIndex}`);
    }

    const children = dsIndexes.filter(
      ({ ds: childDs }) => childDs.parentIndex === matchingDs.index
    );

    const treeItem: TreeDataSource = {
      type: matchingDs.ds.type,
      children: children.map(({ index }) => mapToTreeDataSource(index)),
    };

    return treeItem;
  };

  return mapToTreeDataSource(0);
};

/**
 * Possible field that can be selected for given data sources
 */
export type AllowedField = {
  value: string;
  label: string;
  dataSourceIndex: number;
  fieldId: string;
  fieldDef: FieldDefinition;
};

/**
 * Give a field value dataSource|fieldId, returns a object with data source index and field id as fields
 * @param value
 * @returns
 */
export const getFieldFromValue = (value: string) => {
  const [dataSourceIndex, fieldId] = value?.split('|') ?? ['0', ''];

  return {
    dataSourceIndex: parseInt(dataSourceIndex),
    fieldId,
  };
};

/**
 * Retrieve a field definition
 * @param dataSources
 * @param field
 * @returns
 */
export const getFieldDefinition = (
  dataSources: RelatedDataSource[],
  field: { dataSourceIndex: number; fieldId: string },
  customAttributeSchemas: CustomAttributeSchemaLookup
): FieldDefinition => {
  const sharedDatasets = getSharedDatasets();
  const dataSourceType = dataSources[field.dataSourceIndex].type;
  const dataSourceDefinition = sharedDatasets[dataSourceType];

  if (!field.fieldId.startsWith(customAttributeFieldIdPrefix)) {
    const fieldDefinition = dataSourceDefinition.fields[field.fieldId];
    const sharedFieldDefinition =
      sharedDatasets[dataSourceType].fields[field.fieldId];
    if (!sharedFieldDefinition) {
      throw new Error(`Field not found for ${dataSourceType} ${field.fieldId}`);
    }

    return { ...fieldDefinition, dataType: sharedFieldDefinition.dataType };
  }

  if (!dataSourceDefinition.customAttributeFormConfigurationParentTypes) {
    throw new Error(`Field not found for ${dataSourceType} ${field.fieldId}`);
  }

  const customAttributes = getCustomAttributeFields({
    dataSourceIndex: field.dataSourceIndex,
    ds: sharedDatasets[dataSourceType],
    customAttributeSchemas,
    dataSources,
  });

  const customAttribute = customAttributes.find(
    (c) => c.fieldId === field.fieldId
  );

  if (!customAttribute) {
    throw new Error(`Custom attribute not found ${field.fieldId}`);
  }

  return {
    label: customAttribute.label,
    dataType: 'text',
    displayType: 'text',
  };
};

/**
 * Returns a label that is prefixed with the data source names
 * @param label
 * @returns
 */
const getNestedLabel = (
  dataSourceIndex: number,
  dataSources: RelatedDataSource[],
  label: string
) => {
  let parentDataSource: null | RelatedDataSource = dataSources[dataSourceIndex];
  const sharedDatasets = getSharedDatasets();
  while (parentDataSource) {
    const parentDs = sharedDatasets[parentDataSource.type];
    label = `${parentDs.label} / ${label}`;
    if (parentDataSource.parentIndex !== undefined) {
      parentDataSource = dataSources[parentDataSource.parentIndex];
    } else {
      parentDataSource = null;
    }
  }

  return label;
};

/**
 * Returns list of fields with labels and type info for a custom data source
 * @param customDatasource
 * @returns
 */
export const getCustomDatasourceFields = (
  customDatasource: Pick<TypedCustomDatasource, 'Datasources' | 'Fields'>,
  customAttributeSchemas: CustomAttributeSchemaLookup
): AllowedField[] => {
  const allowedFields = getAllowedFieldsFromDataSourceArray(
    customDatasource.Datasources,
    customAttributeSchemas
  );

  return (customDatasource.Fields ?? []).map(
    (df) =>
      allowedFields.find(
        (f) =>
          df.dataSourceIndex === f.dataSourceIndex && df.fieldId == f.fieldId
      )!
  );
};

export const getAllowedFieldsFromDataSourceArray = (
  dataSources: RelatedDataSource[],
  customAttributeSchemas: CustomAttributeSchemaLookup
): AllowedField[] => {
  const fields: AllowedField[] = [];
  const sharedDatasets = getSharedDatasets();
  dataSources.forEach((dataSource, dataSourceIndex) => {
    const ds = sharedDatasets[dataSource.type];

    for (const fieldId in ds.fields) {
      const fieldDef = ds.fields[fieldId];
      // Some columns can only be displayed as child
      if (fieldDef.onlyShowIfChild && dataSourceIndex === 0) {
        continue;
      }
      const field = ds.fields[fieldId];
      const label = field.label;

      fields.push({
        value: getFieldValue({ dataSourceIndex, fieldId }),
        fieldId,
        label: getNestedLabel(dataSourceIndex, dataSources, label),
        dataSourceIndex,
        fieldDef,
      });
    }
    fields.push(
      ...getCustomAttributeFields({
        dataSourceIndex,
        ds,
        dataSources,
        customAttributeSchemas,
      })
    );
  });

  return fields;
};

/**
 * Get list of fields that can be displayed based on the selected data sources
 * @param root
 * @returns
 */
export const getAllowedFields = (
  root: TreeDataSource | undefined,
  customAttributeSchemas: CustomAttributeSchemaLookup = {}
): AllowedField[] => {
  const fields: AllowedField[] = [];
  if (!root?.type) {
    return fields;
  }
  const dataSources = getFlattenedDataSources(root);

  return getAllowedFieldsFromDataSourceArray(
    dataSources,
    customAttributeSchemas
  ).sort((a, b) => a.label.localeCompare(b.label));
};

export const getFieldValue = ({
  dataSourceIndex,
  fieldId,
}: {
  dataSourceIndex: number;
  fieldId: string;
}) => `${dataSourceIndex}|${fieldId}`;

const getCustomAttributeFields = ({
  dataSourceIndex,
  ds,
  dataSources,
  customAttributeSchemas,
}: {
  dataSourceIndex: number;
  dataSources: RelatedDataSource[];
  ds: SharedDataset;
  customAttributeSchemas: CustomAttributeSchemaLookup;
}): AllowedField[] => {
  if (!ds.customAttributeFormConfigurationParentTypes) {
    return [];
  }
  const allFields: AllowedField[] = [];
  for (const parentType of ds.customAttributeFormConfigurationParentTypes) {
    const fields = getCustomAttributeFieldsForParentType({
      dataSourceIndex,
      dataSources,
      customAttributeSchemas,
      parentType,
    });
    allFields.push(...fields);
  }

  return allFields;
};

const getCustomAttributeFieldsForParentType = ({
  dataSourceIndex,
  dataSources,
  parentType,
  customAttributeSchemas,
}: {
  dataSourceIndex: number;
  dataSources: RelatedDataSource[];
  parentType: Parent_Type_Enum;
  customAttributeSchemas: CustomAttributeSchemaLookup;
}): AllowedField[] => {
  const customAttributeSchema = customAttributeSchemas[parentType];
  if (!customAttributeSchema) {
    return [];
  }
  const customAttributeFields = getCustomAttributeRenderProps(
    customAttributeSchema.Schema,
    customAttributeSchema.UiSchema
  );
  const fields: AllowedField[] = [];
  for (const customAttributeField of customAttributeFields) {
    const fieldId = `${customAttributeFieldIdPrefix}${customAttributeField.path}`;
    fields.push({
      value: getFieldValue({ dataSourceIndex, fieldId }),
      fieldId,
      label: getNestedLabel(
        dataSourceIndex,
        dataSources,
        customAttributeField.label
      ),
      dataSourceIndex,
      fieldDef: getCustomAttributeFieldDef(customAttributeField),
    });
  }

  return fields;
};

const getCustomAttributeFieldDef = (
  customAttributeField: FieldRendererProps
): FieldDefinition => {
  switch (customAttributeField.type) {
    case FieldType.Select:
      return {
        label: customAttributeField.label,
        displayType: 'options',
        dataType: 'text',
        getOptions: () =>
          customAttributeField.options?.map((o) => ({ value: o, label: o })) ??
          [],
      };
    case FieldType.Link:
      return {
        label: customAttributeField.label,
        displayType: 'link',
        dataType: 'text',
      };
    case FieldType.Date:
      return {
        label: customAttributeField.label,
        displayType: 'date',
        dataType: 'text',
      };
    default:
      return {
        label: customAttributeField.label,
        displayType: 'text',
        dataType: 'text',
      };
  }
};

/**
 * Given a custom report definition and aggregate type, returns a list of allow fields that can be used for the Y-axis
 * @param reportDefinition
 */
export const getChartYFields = (
  customDatasource: TypedCustomDatasource,
  aggregationType: AggregateType,
  customAttributeSchemas: CustomAttributeSchemaLookup
) => {
  const customDatasourceFields = getCustomDatasourceFields(
    customDatasource,
    customAttributeSchemas
  );

  return (
    customDatasourceFields.filter((f) => {
      return aggregateTypeSupportedDataTypes[aggregationType].includes(
        f.fieldDef.dataType
      );
    }) ?? []
  ).map((f) => ({
    label: f.label,
    value: f.value,
  }));
};
/**
 * Given a custom report definition returns its fields
 * @param reportDefinition
 */
export const getFields = (
  { Datasources, Fields }: TypedCustomDatasource,
  customAttributeSchemas: CustomAttributeSchemaLookup
): AllowedField[] =>
  Fields?.map((f) => {
    const fieldDef = getFieldDefinition(Datasources, f, customAttributeSchemas);

    return { fieldDef, value: getFieldValue(f), label: fieldDef.label, ...f };
  }) ?? [];
