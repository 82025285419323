import BarChart from '@cloudscape-design/components/bar-chart';
import dayjs from 'dayjs';
import type { KeyPrefix } from 'i18next';
import { isDate } from 'lodash';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useDashboardStore } from 'src/pages/dashboards/useDashboardStore';

import type { UseRiskScoreFormattersResponse } from '@/hooks/useRiskScore';

import type { UseRatingResponse } from '../../../../../../../components/hooks/useRating';
import { NoWidgetData } from '../../../widgets/NoWidgetData';
import { WidgetLoading } from '../../../widgets/WidgetLoading';
import { useAggregateCategories } from '../../hooks/useAggregateCategories';
import { useGetWidgetData } from '../../hooks/useGetWidgetData';
import type {
  CategoricalGigawidgetCommonProps,
  Category,
  CategoryGetter,
  CategoryType,
  DataSourceItem,
  GigawidgetCommonProps,
  UnratedCategoryType,
  WidgetDataSource,
} from '../../types';
import { onlyShowIntegers } from '../../util/onlyShowIntegers';
import { useBarClickthrough } from './hooks/useBarClickthrough';
import { useDataSeries } from './hooks/useDataSeries';
import { useXDomain } from './hooks/useXDomain';
import styles from './styles.module.scss';

export type GigaBarWidgetProps<
  TDataSource extends WidgetDataSource,
  TCategory extends CategoryType,
  TSubCategory extends CategoryType,
> = GigawidgetCommonProps<TDataSource> &
  CategoricalGigawidgetCommonProps<TDataSource, TCategory> & {
    orientation: 'horizontal' | 'vertical';
    stackedBars?: boolean;
    subCategoryGetter?: CategoryGetter<TDataSource, TSubCategory>;
    subCategoryRatingTranslationKey?: KeyPrefix<'ratings'>;
    subCategoryOverrideFunction?: (
      category: Category<
        DataSourceItem<TDataSource>,
        TSubCategory | UnratedCategoryType
      >,
      ratingFns: UseRatingResponse,
      riskFormatters: UseRiskScoreFormattersResponse
    ) => Partial<{
      color: string;
      title: string;
      category: Category<
        DataSourceItem<TDataSource>,
        TSubCategory | UnratedCategoryType
      >;
      value: number;
    }>;
  };

export const GigaBarWidget = <
  TDataSource extends WidgetDataSource,
  TCategory extends CategoryType,
  TSubCategory extends CategoryType,
>({
  dataSource,
  variables,
  categoryGetter,
  orientation,
  dateFilterOptions,
  subCategoryGetter,
  subCategoryRatingTranslationKey,
  stackedBars,
  onClickUrl,
  categoryRatingTranslationKey,
  propertyFilterQuery,
  aggregationType,
  aggregationField,
  categoryOverrideFunction,
  subCategoryOverrideFunction,
}: GigaBarWidgetProps<TDataSource, TCategory, TSubCategory>) => {
  const { filters } = useDashboardStore();

  const {
    tableProps: { allItems },
    loading,
  } = useGetWidgetData({
    dataSource,
    variables,
    dateFilterOptions,
    propertyFilterQuery,
  });

  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);

  const countedCategories = useAggregateCategories({
    dataSource,
    items: allItems,
    dateFilterOptions,
    categoryGetter,
    subCategoryGetter,
    aggregationType,
    aggregationField,
  });

  const xDomain = useXDomain(countedCategories, dateFilterOptions);

  const dataSeries = useDataSeries(countedCategories, {
    entityNamePlural: dataSource.entityNamePlural,
    category: categoryRatingTranslationKey,
    subCategory: subCategoryRatingTranslationKey,
    categoryOverrideFunction: categoryOverrideFunction,
    subCategoryOverrideFunction: subCategoryOverrideFunction,
  });

  const handleClick = useBarClickthrough(ref, countedCategories, (cat) => {
    if (onClickUrl) {
      const url = onClickUrl(cat, filters);
      if (url) {
        navigate(url);
      }
    }
  });

  const { t } = useTranslation();

  if (!allItems) {
    return <WidgetLoading />;
  }

  return (
    <div ref={ref} className={styles.barChart} onClick={handleClick}>
      <BarChart
        aggregationType={aggregationType}
        statusType={loading ? 'loading' : 'finished'}
        hideLegend={!subCategoryGetter}
        hideFilter={true}
        series={dataSeries}
        height={0}
        stackedBars={stackedBars}
        //@ts-ignore
        xDomain={xDomain}
        yTitle={`No. of ${t(dataSource.entityNamePlural)}`}
        fitHeight={true}
        ariaDescription={''}
        empty={<NoWidgetData />}
        ariaLabel={''}
        yTickFormatter={onlyShowIntegers}
        xTickFormatter={(value) =>
          isDate(value)
            ? dayjs(value).format(dateFilterOptions?.dateFormat)
            : String(value)
        }
        horizontalBars={orientation === 'horizontal'}
      />
    </div>
  );
};
