import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import { uuid4 } from '@sentry/core';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import AssessmentFormFields from 'src/pages/assessments/forms/AssessmentForm/AssessmentFormFields';
import {
  type AssessmentFormDataFields,
  AssessmentFormSchema,
  defaultValues,
} from 'src/pages/assessments/forms/AssessmentForm/assessmentSchema';
import { useAssessmentTypeConfig } from 'src/pages/assessments/useAssessmentTypeConfig';

import {
  departmentInsertInputBuilder,
  ownerAndContributorInsertFields,
  tagInsertInputBuilder,
} from '@/components/Form';
import { ModalForm } from '@/components/Form/Form/ModalForm';
import {
  Assessment_Status_Enum,
  useInsertAssessmentMutation,
  useInsertWizardMutation,
} from '@/generated/graphql';
import { handleError } from '@/utils/errorUtils';

import { useNavigateToInProgress } from '../hooks/useNavigateToInProgress';
import { useWizardStore } from '../store/useWizardStore';

type CreateAssessmentModalProps = {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  basePath: string;
  riskId: string;
};

export const CreateAssessmentModal: FC<CreateAssessmentModalProps> = ({
  isVisible,
  setIsVisible,
  basePath,
  riskId,
}) => {
  const { parentType, taxonomyKey } = useAssessmentTypeConfig('rating');
  const { addNotification } = useNotifications();
  const { user } = useRisksmartUser();
  const { navigateToInProgress } = useNavigateToInProgress();
  const { addActiveWizard, setAssessmentId } = useWizardStore();
  const { t } = useTranslation(['common']);
  const { t: ts } = useTranslation(['common'], { keyPrefix: taxonomyKey });

  const [insertAssessmentMutation] = useInsertAssessmentMutation();

  const [insertWizardMutation] = useInsertWizardMutation({
    variables: {
      object: { RiskId: riskId },
    },
  });

  const onSave = async (variables: AssessmentFormDataFields) => {
    const Id = uuid4();
    const { data } = await insertAssessmentMutation({
      onError: (error) => {
        handleError(error);
        addNotification({
          type: 'error',
          content: <>{error.message}</>,
        });
      },
      variables: {
        ...variables,
        Id,
        CustomAttributeData: variables.CustomAttributeData || undefined,
        ...ownerAndContributorInsertFields(variables, Id),
        Tags: tagInsertInputBuilder(variables.TagTypeIds, Id),
        Departments: departmentInsertInputBuilder(
          variables.DepartmentTypeIds,
          Id
        ),
        CompletedByUser: variables.CompletedByUser?.value,
      },
    });

    await insertWizardMutation({
      onCompleted: () => {
        addActiveWizard(riskId);
        navigateToInProgress(basePath);
        setAssessmentId(data?.insert_assessment_one?.Id ?? '');
      },
      onError: (error) => {
        handleError(error);
        addNotification({
          type: 'error',
          content: <>{error.message}</>,
        });
      },
      variables: {
        object: {
          RiskId: riskId,
          AssessmentId: data?.insert_assessment_one?.Id,
        },
      },
    });
  };

  const onDismiss = async () => {
    setIsVisible(false);
  };

  const defaultData: AssessmentFormDataFields = {
    ...defaultValues,
    Owners: [
      {
        type: 'user',
        value: user!.userId,
      },
    ],
    Status: Assessment_Status_Enum.Inprogress,
  };

  return (
    <ModalForm
      schema={AssessmentFormSchema}
      defaultValues={defaultData}
      i18n={{
        entity_name: ts('entity_name'),
        edit_modal_title: t('details'),
        create_modal_title: t('details'),
      }}
      testId={'create-assessment-form-settings-button'}
      formId={'create-assessment-form'}
      parentType={parentType}
      onSave={onSave}
      onDismiss={onDismiss}
      visible={isVisible}
    >
      <AssessmentFormFields assessmentMode={'rating'} />
    </ModalForm>
  );
};
