import BarChart from '@cloudscape-design/components/bar-chart';
import type { MixedLineBarChartProps } from '@cloudscape-design/components/mixed-line-bar-chart/interfaces';
import type { DataType } from '@risksmart-app/shared/reporting/datasets/types';
import { type FC, useRef } from 'react';
import { nullDataChartLabel } from 'src/pages/custom-datasources/update/nullData';

import { genericCategoricalPalette } from '@/utils/colours';

import { NoWidgetData } from '../../../widgets/NoWidgetData';
import { useXDomain } from '../../useXDomain';
import { getClickedLabel } from '../barChart';
import type { DatePrecision, Series } from '../types';
import type { SegmentData } from '../WidgetPieChart';
import styles from './styles.module.scss';

export type Props = {
  loading: boolean;
  series: Series[];
  xAxisDataType: DataType;
  xAxisDatePrecision: DatePrecision | null;
  stackedBars?: boolean;
  onSegmentClick?: (data: SegmentData) => void;
};

export const WidgetBarChart: FC<Props> = ({
  loading,
  series,
  xAxisDataType,
  xAxisDatePrecision,
  stackedBars,
  onSegmentClick,
}) => {
  const xDomain = useXDomain({
    datePrecision: xAxisDatePrecision,
    dataType: xAxisDataType,
    xAxisData: series
      .flatMap((s) => s.data)
      .filter((d) => d.x)
      .map((d) => d.x as string),
  });

  const hasNull = series.flatMap((s) => s.data).find((d) => d.x === null);
  const ref = useRef<HTMLDivElement>(null);

  if (hasNull && xDomain) {
    xDomain.push(nullDataChartLabel());
  }

  const handleClick = () => {
    const label = getClickedLabel(ref);
    if (label !== undefined) {
      const dataItem = series
        .flatMap((s) => s.data)
        .find((d) => d.label === label);
      if (dataItem) {
        onSegmentClick?.({ value: dataItem.x });
      }
    }
  };

  return (
    <div className={styles.barChart} ref={ref} onClick={handleClick}>
      <BarChart<Date | number | string>
        statusType={loading ? 'loading' : 'finished'}
        hideFilter={true}
        height={0}
        fitHeight={true}
        empty={<NoWidgetData />}
        hideLegend={series.length <= 1}
        xDomain={xDomain}
        stackedBars={stackedBars}
        series={series.map((d, i) => ({
          title: d.title as string,
          type: 'bar',
          color: d.color ?? genericCategoricalPalette(i),
          data: d.data.map((d) => ({
            x: d.label,
            y: d.y,
          })) as ReadonlyArray<MixedLineBarChartProps.Datum<string>>,
        }))}
      />
    </div>
  );
};
