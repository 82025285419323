import type { FieldValues } from 'react-hook-form';

import { usePopoverHelp } from '@/components/HelpPanel/usePopoverHelp';

import { CustomisableFormContext } from './CustomisableFormContext';
import type { ModalProps } from './ModalWrapper';
import { ModalWrapper } from './ModalWrapper';
import type { CommonProps } from './types';

export const ModalForm = <TFieldValues extends FieldValues>(
  props: CommonProps<TFieldValues> & ModalProps
) => {
  usePopoverHelp();

  return (
    <CustomisableFormContext
      {...props}
      renderTemplate={(renderProps) => (
        <ModalWrapper
          {...renderProps}
          visible={props.visible}
          testId={props.testId}
        />
      )}
    />
  );
};
