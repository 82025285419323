import type { DataType } from '@risksmart-app/shared/reporting/datasets/types';
import type { FC } from 'react';
import type { TypedCustomDatasource } from 'src/pages/custom-datasources/types';
import ReportTable from 'src/pages/custom-datasources/update/ReportTable';
import type { CustomAttributeSchemaLookup } from 'src/pages/custom-datasources/update/types';

import NumberWidget from '../../widgets/NumberWidget/NumberWidget';
import type { ChartType } from '../form/customDataSourceWidgetSettingsSchema';
import { WidgetBarChart } from './BarChart/WidgetBarChart';
import type { DatePrecision, ReportField, Series } from './types';
import { WidgetPieChart } from './WidgetPieChart';

export type Props = {
  chartType: ChartType;
  loading: boolean;
  xAxisDataType: DataType;
  xAxisDatePrecision: DatePrecision | null;
  seriesData: Series[];
  innerMetricValue?: string;
  reportData: ReportField[][];
  reportDataDefinition: Pick<TypedCustomDatasource, 'Datasources' | 'Fields'>;
  onPageChangeClick?: (e: { requestedPageIndex: number }) => void;
  onCategoryClick?: (item: { value: unknown }) => void;
  pageSize?: number;
  currentPageIndex?: number;
  customAttributeSchemaLookup: CustomAttributeSchemaLookup;
};

export const WidgetChart: FC<Props> = ({
  chartType,
  loading,
  xAxisDataType,
  xAxisDatePrecision,
  seriesData,
  innerMetricValue,
  reportData,
  reportDataDefinition,
  onPageChangeClick,
  onCategoryClick,
  pageSize,
  currentPageIndex,
  customAttributeSchemaLookup,
}) => {
  const firstSeries = seriesData?.[0];
  switch (chartType) {
    case 'table':
      return (
        <ReportTable
          variant={'embedded'}
          definition={reportDataDefinition}
          items={reportData}
          onPageChangeClick={onPageChangeClick}
          currentPageIndex={currentPageIndex ?? 0}
          pageSize={pageSize ?? 20}
          loading={loading}
          customAttributeSchemaLookup={customAttributeSchemaLookup}
        />
      );
    case 'kpi':
      return (
        <NumberWidget
          loading={loading}
          value={
            firstSeries?.data.length === 0
              ? undefined
              : (firstSeries.data[0].y as number)
          }
        />
      );
    case 'bar':
    case 'stacked-bar':
      return (
        <WidgetBarChart
          onSegmentClick={onCategoryClick}
          loading={loading}
          stackedBars={chartType === 'stacked-bar'}
          xAxisDataType={xAxisDataType}
          xAxisDatePrecision={xAxisDatePrecision}
          series={seriesData}
        />
      );
    case 'pie':
    case 'donut':
      return (
        <WidgetPieChart
          onSegmentClick={onCategoryClick}
          innerMetricValue={innerMetricValue}
          loading={loading}
          data={firstSeries?.data}
          donut={chartType === 'donut'}
        />
      );
  }
};
