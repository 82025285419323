import type { ReportField } from 'src/pages/dashboards/CustomDataSourceWidget/WidgetChart/types';

import type { GetReportingDataQuery } from '@/generated/graphql';

import type { TypedCustomDatasource } from '../types';
import { displayTypes } from '../update/displayTypes';
import type { CustomAttributeSchemaLookup } from '../update/types';
import { getCustomDatasourceFields } from '../update/utils';
import { useCustomDatasourceHelpers } from '../useCustomDatasourceHelpers';

export const useCustomDatasourceExporterMapper = ({
  customDatasource,
  customAttributeSchemaLookup,
}: {
  customDatasource:
    | null
    | Pick<TypedCustomDatasource, 'Datasources' | 'Fields'>
    | undefined;
  customAttributeSchemaLookup: CustomAttributeSchemaLookup | null | undefined;
}) => {
  const helpers = useCustomDatasourceHelpers();

  return (results: GetReportingDataQuery['reportingData']) => {
    if (!customDatasource) {
      throw new Error('Missing custom data source');
    }
    if (!customAttributeSchemaLookup) {
      throw new Error('Missing custom attribute schema');
    }
    const fields = getCustomDatasourceFields(
      customDatasource,
      customAttributeSchemaLookup
    );

    const headers = fields.map((f) => f.label);
    const dataAndHeaders = [
      headers,
      ...(results?.map((r) =>
        r.map((f, i) => {
          const fieldDef = fields[i].fieldDef;
          const displayType = displayTypes[fieldDef.displayType];
          const formatter =
            displayType.exportVal ?? displayType.getChartLabel ?? null;
          const label =
            formatter?.({
              fieldData: f as ReportField,
              fieldDef,
              helpers,
              groupByDatePrecision: null,
            }) ?? '';

          return label;
        })
      ) ?? []),
    ];

    return dataAndHeaders;
  };
};
