import Box from '@cloudscape-design/components/box';
import { useRating } from '@risksmart-app/components/hooks/useRating';
import type { TypedPropertyFilterQuery } from '@risksmart-app/components/Table/tableUtils';
import _ from 'lodash';
import type { Ref } from 'react';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import type { RiskRegisterFields } from 'src/pages/risks/types';
import { useAggregation } from 'src/providers/useAggregation';
import { merge } from 'ts-deepmerge';

import {
  Risk_Assessment_Result_Control_Type_Enum,
  Risk_Scoring_Model_Enum,
} from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { emptyFilterQuery } from '@/utils/table/types';

import { useDashboardWidgetSettings } from '../../../../context/useDashboardWidgetSettings';
import { useGetWidgetData } from '../../Gigawidget/hooks/useGetWidgetData';
import type { WidgetRef } from '../../types';
import { dataSources } from '../../UniversalWidget/dataSources';
import type { FilterSettings } from '../../UniversalWidget/util';
import {
  convertToTokenGroups,
  dashboardFilterToQuery,
} from '../../UniversalWidget/util';
import { useDashboardStore } from '../../useDashboardStore';
import HeatmapWidget from '../HeatmapWidget/HeatmapWidget';
import { useGetRiskAssessmentRatingsData } from './useGetRiskAssessmentRatingsData';
import { WidgetSettingsModal } from './WidgetSettingsModal';

type Props = {
  controlType: Risk_Assessment_Result_Control_Type_Enum;
};

export const RiskHeatmap = forwardRef((props: Props, ref: Ref<WidgetRef>) => {
  const [showModal, setShowModal] = useState(false);
  const [settings, setSettings] = useDashboardWidgetSettings<FilterSettings>();
  const navigate = useNavigate();
  const { riskModel } = useAggregation();
  const hasImpacts = useIsFeatureVisibleToOrg('impacts');
  const nonDefaultAggregation = riskModel !== Risk_Scoring_Model_Enum.Default;
  const { getLabel: getImpactLabel } = useRating('impact');
  const { getLabel: getLikelihoodLabel } = useRating('likelihood');
  const { t } = useTranslation(['common'], {
    keyPrefix:
      props.controlType === Risk_Assessment_Result_Control_Type_Enum.Controlled
        ? 'dashboard.widgets.controlledRiskHeatMap'
        : 'dashboard.widgets.uncontrolledRiskHeatMap',
  });
  useImperativeHandle(ref, () => ({
    openSettings: () => setShowModal(true),
  }));

  const { filters } = useDashboardStore();
  const dataSource = dataSources.risk;
  const {
    tableProps: { allItems },
    loading,
  } = useGetWidgetData({
    dataSource,
    propertyFilterQuery: settings?.filtering,
  });
  const handleSave = async (data: FilterSettings) => {
    setSettings(data);
  };

  const data = useGetRiskAssessmentRatingsData(props.controlType, allItems);

  if (nonDefaultAggregation || hasImpacts) {
    return (
      <div className={'flex justify-center items-center h-full'}>
        {t('disabledMessage')}
      </div>
    );
  }

  return (
    <>
      {showModal && (
        <WidgetSettingsModal
          onDismiss={() => setShowModal(false)}
          onSave={handleSave}
        />
      )}
      <HeatmapWidget
        onCellClick={(cell) => {
          const localPropertyFilter: TypedPropertyFilterQuery<RiskRegisterFields> =
            settings?.filtering
              ? convertToTokenGroups(settings.filtering)
              : emptyFilterQuery;
          const cellPropertyFilter: TypedPropertyFilterQuery<RiskRegisterFields> =
            {
              operation: 'and',
              tokens: [],
              tokenGroups: [
                {
                  propertyKey:
                    props.controlType ===
                    Risk_Assessment_Result_Control_Type_Enum.Controlled
                      ? 'ControlledImpact'
                      : 'UncontrolledImpact',
                  value: getImpactLabel(cell.x + 1),
                  operator: '=',
                },
                {
                  propertyKey:
                    props.controlType ===
                    Risk_Assessment_Result_Control_Type_Enum.Controlled
                      ? 'ControlledLikelihood'
                      : 'UncontrolledLikelihood',
                  value: getLikelihoodLabel(cell.y + 1),
                  operator: '=',
                },
              ],
            };
          const url = dataSource.clickThroughUrl?.(
            merge(
              localPropertyFilter,
              dashboardFilterToQuery(filters, 'day', undefined, {
                departments:
                  !!dataSource.dashboardFilterConfig.departmentsFilter,
                tags: !!dataSource.dashboardFilterConfig.tagsFilter,
              }),
              cellPropertyFilter
            )
          );
          if (url) {
            navigate(url);
          }
        }}
        data={data}
        loading={loading}
        xAxisTitle={t('xAxisTitle')}
        yAxisTitle={t('yAxisTitle')}
        renderPopoverContent={(data) => (
          <div>
            <Box variant={'strong'}>
              {t('popover.likelihood')}
              {':'}
            </Box>
            <Box>{getLikelihoodLabel(data.y + 1)}</Box>
            <Box variant={'strong'}>
              {t('popover.impact')}
              {':'}
            </Box>
            <Box>{getImpactLabel(data.x + 1)}</Box>
            <Box variant={'strong'}>
              {t('popover.recordCount')}
              {':'}
            </Box>
            <Box>{data.data.value}</Box>
            {data.data.label && (
              <>
                <Box variant={'strong'}>
                  {t('popover.label')}
                  {':'}
                </Box>
                <Box>{data.data.label}</Box>
              </>
            )}
          </div>
        )}
      />
    </>
  );
});

RiskHeatmap.displayName = 'RiskHeatMap';

export default RiskHeatmap;
