import type { FilteringProperty } from '@cloudscape-design/components/property-filter/interfaces';
import Link from '@risksmart-app/components/Link';

import { nullDataChartLabel } from '../nullData';
import type { AllowedField } from '../utils';
import type { ReportFieldType } from './types';

export const link: ReportFieldType = {
  asyncOptionSuggestions: true,
  cell: ({ fieldData }) => {
    const value = fieldData.value;

    return (
      <Link href={value as string} target={'_blank'}>
        {value}
      </Link>
    );
  },
  exportVal: ({ fieldData }) => {
    return (fieldData.value as string) ?? '';
  },
  getChartLabel: ({ fieldData }) => {
    return (fieldData.value as string) ?? nullDataChartLabel();
  },
  propertyConfig: function (field: AllowedField): FilteringProperty {
    return {
      key: field.value,
      groupValuesLabel: '',
      propertyLabel: field.label,
      operators: [
        {
          operator: '=',
        },
      ],
    };
  },
};
