import type { FilteringProperty } from '@cloudscape-design/components/property-filter/interfaces';

import SimpleRatingBadge from '@/components/SimpleRatingBadge';

import { nullDataChartLabel } from '../nullData';
import type { AllowedField } from '../utils';
import type { ReportFieldType } from './types';

export const metaRating: ReportFieldType = {
  asyncOptionSuggestions: true,
  getChartColor({ fieldData, fieldDef }) {
    if (fieldDef.displayType !== 'metaRating') {
      throw new Error('metaRating filed type used out of context');
    }

    return fieldData.meta?.color;
  },
  exportVal: ({ fieldData, fieldDef }) => {
    const value = fieldData.value;
    if (fieldDef.displayType !== 'metaRating') {
      throw new Error('metaRating filed type used out of context');
    }

    return (value ?? '') as string;
  },
  getChartLabel: ({ fieldData, fieldDef }) => {
    const value = fieldData.value;
    if (fieldDef.displayType !== 'metaRating') {
      throw new Error('metaRating filed type used out of context');
    }

    return (value ?? nullDataChartLabel()) as string;
  },
  cell: function ({ fieldDef, fieldData }) {
    const value = fieldData.value;
    if (fieldDef.displayType !== 'metaRating') {
      throw new Error('metaRating filed type used out of context');
    }

    return (
      <SimpleRatingBadge
        rating={{ color: fieldData.meta?.color, label: value as string }}
      />
    );
  },
  propertyConfig: function (field: AllowedField): FilteringProperty {
    return {
      key: field.value,
      groupValuesLabel: '',
      propertyLabel: field.label,
      operators: [
        {
          operator: '=',
        },
      ],
    };
  },
};
