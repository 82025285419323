import { useRating } from '@risksmart-app/components/hooks/useRating';

import useEntityInfo from '@/hooks/getEntityInfo';
import { useCommonLookupLazy } from '@/hooks/useCommonLookupLazy';

import type { Helpers } from './update/displayTypes/types';

export const useCustomDatasourceHelpers = () => {
  const getEntityInfo = useEntityInfo();
  const {
    getByValueAndRatingKey: getRatingByValue,
    getOptionsByRatingKey: getRatingOptions,
  } = useRating();
  const {
    getByValue: getCommonLookupByValue,
    getOptions: getCommonLookupOptions,
  } = useCommonLookupLazy();

  const helpers: Helpers = {
    getRatingByValue,
    getCommonLookupByValue,
    getRatingOptions,
    getCommonLookupOptions,
    getEntityInfo,
  };

  return helpers;
};
