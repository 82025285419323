import { useMemo } from 'react';
import type { CustomAttributeSchemaLookup } from 'src/pages/custom-datasources/update/types';

import type { GetFormConfigurationQuery } from '@/generated/graphql';
import { useGetFormConfigurationQuery } from '@/generated/graphql';

export const createCustomAttributeSchemaLookup = (
  formConfigurationData: GetFormConfigurationQuery | undefined
): CustomAttributeSchemaLookup | undefined => {
  const schemaConfigurations: CustomAttributeSchemaLookup = {};
  const formConfigurations = formConfigurationData?.form_configuration;
  if (!formConfigurations) {
    return undefined;
  }
  for (const formConfig of formConfigurations) {
    if (formConfig.customAttributeSchema) {
      schemaConfigurations[formConfig.ParentType] =
        formConfig.customAttributeSchema;
    }
  }

  return schemaConfigurations;
};

export const useCustomAttributeLookup = () => {
  const { data: formConfigurationData, loading } = useGetFormConfigurationQuery(
    {
      fetchPolicy: 'no-cache',
    }
  );
  const customAttributeSchemaLookup = useMemo(
    () => createCustomAttributeSchemaLookup(formConfigurationData),
    [formConfigurationData]
  );

  return {
    customAttributeSchemaLookup,
    loading,
  };
};
