import { z } from 'zod';

import { aggregateType, dataSourceTypeSchema } from '../schema';

export const datasourceRequestSchema = z.object({
  type: dataSourceTypeSchema,
  /**
   * Parent datasource to join with
   */
  parentIndex: z.number().int().optional(),
});

export const datasourceFieldSchema = z.object({
  fieldId: z.string(),
  dataSourceIndex: z.number().int(),
});

export const operatorSchema = z.enum([
  '=',
  '!=',
  '<',
  '>',
  '<=',
  '>=',
  'contains',
]);

export type Operator = z.infer<typeof operatorSchema>;

export const groupByDatePrecision = z.enum(['day', 'month', 'year']);

export type GroupByDatePrecision = z.infer<typeof groupByDatePrecision>;

export const filterSchema = z.object({
  field: datasourceFieldSchema,
  value: z.string().or(z.number()).or(z.null()),
  operator: operatorSchema,
});

const filterBaseSchema = z.object({
  operation: z.enum(['and', 'or']),
});

export type FilterGroup = z.infer<typeof filterBaseSchema> & {
  filters: (Filter | FilterGroup)[];
};

export const filterGroupSchema: z.ZodType<FilterGroup> =
  filterBaseSchema.extend({
    filters: z.lazy(() => z.array(filterGroupSchema.or(filterSchema))),
  });

export type Filter = z.infer<typeof filterSchema>;

export type DataSourceField = z.infer<typeof datasourceFieldSchema>;

export type DataSourceRequest = z.infer<typeof datasourceRequestSchema>;

const groupBySchema = z.object({
  field: datasourceFieldSchema,
  datePrecision: groupByDatePrecision.nullish(),
});

export type GroupBy = z.infer<typeof groupBySchema>;

export const PostSchema = z.object({
  Input: z
    .object({
      dataSources: z.array(datasourceRequestSchema),
      fields: z.array(datasourceFieldSchema),
      filters: filterGroupSchema,
      groupBy: groupBySchema.array().nullish(),
      aggregateType: aggregateType.nullish(),
      aggregateField: datasourceFieldSchema.nullish(),
      limit: z.number(),
      offset: z.number(),
    })
    .superRefine((values, ctx) => {
      if (!values.aggregateType && values.fields.length < 1) {
        ctx.addIssue({
          path: ['fields'],
          code: 'too_small',
          message: 'Required',
          minimum: 1,
          type: 'array',
          inclusive: true,
        });
      }
    }),
});
