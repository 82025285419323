import type { DisplayType } from '@risksmart-app/shared/reporting/datasets/types';

import { badgeList } from './badgeList';
import { commonLookup } from './commonLookup';
import { date } from './date';
import { detailsLink } from './detailsLink';
import { link } from './link';
import { metaRating } from './metaRating';
import { number } from './number';
import { options } from './options';
import { rating } from './rating';
import { text } from './text';
import type { ReportFieldType } from './types';

export const displayTypes: { [type in DisplayType]: ReportFieldType } = {
  rating,
  commonLookup,
  text,
  options,
  metaRating,
  badgeList,
  date,
  number,
  link,
  detailsLink,
};
