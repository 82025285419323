import { useCallback } from 'react';
import { useDashboardStore } from 'src/pages/dashboards/useDashboardStore';

import { sanitizeSettings } from '../pages/dashboards/UniversalWidget/sanitizeSettings';
import type { GigawidgetSettings } from '../pages/dashboards/UniversalWidget/util';
import { useWidgetContext } from '../pages/dashboards/WidgetContext/WidgetContext';

export const useDashboardWidgetSettings = <T = unknown,>(): [
  null | T,
  (settings: T) => void,
] => {
  const widgetData = useWidgetContext();
  const { widgets, setWidgets } = useDashboardStore();

  const widget = widgetData
    ? widgets.find((w) => w.id === widgetData.widgetId)
    : undefined;

  const sanitizedSettings = widget?.settings
    ? sanitizeSettings(widget?.settings as GigawidgetSettings)
    : undefined;

  // Note, widget can be null in previous mode as not yet on the dashboard
  const setWidgetSettings = useCallback(
    (settings: T) => {
      if (!widgetData) {
        return;
      }
      const { widgetId } = widgetData;

      const widget = widgets.find((w) => w.id === widgetId);
      if (widget) {
        widget.settings = settings;
      }
      setWidgets(widgets);
    },
    [setWidgets, widgets, widgetData]
  );

  return [sanitizedSettings as T, setWidgetSettings];
};
