import { useTools } from '@risksmart-app/components/Tools/useTools';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAggregation } from 'src/providers/useAggregation';
import { useCalculateRiskRating } from 'src/ratings/useCalculateRiskRating';

import ControlledAssessmentSelector from '@/components/Form/ControlledAssessmentSelector';
import ControlledComplianceMonitoringAssessmentSelector from '@/components/Form/ControlledComplianceMonitoringAssessmentSelector';
import ControlledDatePicker from '@/components/Form/ControlledDatePicker';
import { ControlledFileUpload } from '@/components/Form/ControlledFileUpload/ControlledFileUpload';
import ControlledInternalAuditReportSelector from '@/components/Form/ControlledInternalAuditReportSelector';
import ControlledRating from '@/components/Form/ControlledRating';
import ControlledRiskMultiSelect from '@/components/Form/ControlledRiskMultiSelect';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import Loading from '@/components/Loading';
import { Risk_Assessment_Result_Control_Type_Enum } from '@/generated/graphql';

import type { AssessmentTypeEnum } from '../useAssessmentTypeConfig';
import type { RiskAssessmentResultFormDataFields } from './riskAssessmentResultSchema';
import { RiskAssessmentResultTestIds } from './RiskAssessmentResultTestIds';
import { useControlTypeLabel } from './useControlTypeLabel';

type Props = {
  readOnly?: boolean;
  showSelector?: AssessmentTypeEnum;
  disableRiskSelector: boolean;
  onControlTypeChange: (
    controlType: Risk_Assessment_Result_Control_Type_Enum
  ) => void;
};

const RiskAssessmentResultForm: FC<Props> = ({
  readOnly,
  onControlTypeChange,
  showSelector,
  disableRiskSelector,
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'assessmentResults.fields',
  });
  const [toolsContent, _] = useTools();

  const getControlTypeLabel = useControlTypeLabel();
  const { control, setValue, watch } =
    useFormContext<RiskAssessmentResultFormDataFields>();
  const { riskModel, loading } = useAggregation();

  const showRatingField = riskModel === 'default';
  const disableControlTypeSelector =
    riskModel === 'control_effectiveness_averages';

  const likelihood = watch('Likelihood');
  const impact = watch('Impact');

  const controlType = watch('ControlType');
  useEffect(() => {
    onControlTypeChange(controlType);
  }, [controlType, onControlTypeChange]);

  const getComputedRating = useCalculateRiskRating(controlType);

  const updateRating = (
    likelihood: null | number | undefined,
    impact: null | number | undefined
  ) => {
    if (likelihood == null || impact == null) {
      return;
    }
    const computedRating = getComputedRating({
      likelihood,
      impact,
    });
    setValue('Rating', computedRating.value);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <CustomisableForm readOnly={readOnly}>
      {showSelector === 'rating' && (
        <ControlledAssessmentSelector
          defaultRequired={toolsContent === 'wizard'}
          addEmptyOption={true}
          readOnly={readOnly}
          disabled={readOnly}
          key={'AssessmentId'}
          name={'AssessmentId'}
          label={t('Assessment')}
          description={t('Assessment_help')}
          control={control}
          testId={RiskAssessmentResultTestIds.Assessment}
        />
      )}
      {showSelector === 'compliance_monitoring_assessment' && (
        <ControlledComplianceMonitoringAssessmentSelector
          defaultRequired={false}
          readOnly={readOnly}
          disabled={readOnly || disableRiskSelector}
          key={'ComplianceMonitoringAssessmentId'}
          name={'ComplianceMonitoringAssessmentId'}
          label={t('ComplianceMonitoringAssessment')}
          description={t('ComplianceMonitoringAssessment_help')}
          control={control}
          testId={RiskAssessmentResultTestIds.ComplianceMonitoringAssessment}
        />
      )}
      {showSelector === 'internal_audit_report' && (
        <ControlledInternalAuditReportSelector
          defaultRequired={false}
          readOnly={readOnly}
          disabled={readOnly || disableRiskSelector}
          key={'InternalAuditReportId'}
          name={'InternalAuditReportId'}
          label={t('InternalAuditReport')}
          description={t('InternalAuditReport_help')}
          control={control}
          testId={RiskAssessmentResultTestIds.InternalAuditReport}
        />
      )}
      <ControlledRiskMultiSelect
        defaultRequired={true}
        key={'RiskIds'}
        testId={RiskAssessmentResultTestIds.Risk}
        control={control}
        label={t('Risk')}
        description={t('Risk_help')}
        disabled={readOnly || disableRiskSelector}
        name={'RiskIds'}
        placeholder={t('Risk_placeholder')}
      />
      <ControlledSelect
        defaultRequired={true}
        forceRequired={true}
        key={'controlType'}
        options={[
          {
            value: Risk_Assessment_Result_Control_Type_Enum.Uncontrolled,
            label: getControlTypeLabel(
              Risk_Assessment_Result_Control_Type_Enum.Uncontrolled
            ),
          },
          {
            value: Risk_Assessment_Result_Control_Type_Enum.Controlled,
            label: getControlTypeLabel(
              Risk_Assessment_Result_Control_Type_Enum.Controlled
            ),
          },
        ]}
        name={'ControlType'}
        label={t('ControlType')}
        description={t('ControlType_help')}
        placeholder={t('ControlType_placeholder')}
        control={control}
        testId={RiskAssessmentResultTestIds.ControlType}
        disabled={readOnly || disableControlTypeSelector}
      />
      <ControlledRating
        defaultRequired={true}
        addEmptyOption={true}
        testId={RiskAssessmentResultTestIds.Likelihood}
        key={'likelihood'}
        name={'Likelihood'}
        label={t('Likelihood')}
        description={t('Likelihood_help')}
        placeholder={t('Likelihood_placeholder')}
        type={'likelihood'}
        control={control}
        disabled={readOnly}
        onChange={(value) => {
          updateRating(value, impact);
        }}
      />
      <ControlledRating
        defaultRequired={true}
        addEmptyOption={true}
        testId={RiskAssessmentResultTestIds.Impact}
        key={'impact'}
        name={'Impact'}
        label={t('Impact')}
        description={t('Impact_help')}
        placeholder={t('Impact_placeholder')}
        type={'impact'}
        control={control}
        disabled={readOnly}
        onChange={(value) => {
          updateRating(likelihood, value);
        }}
      />
      {showRatingField && (
        <ControlledRating
          defaultRequired={true}
          addEmptyOption={true}
          testId={RiskAssessmentResultTestIds.Rating}
          key={'rating'}
          name={'Rating'}
          label={t('Rating')}
          description={t('Rating_help')}
          placeholder={t('Rating_placeholder')}
          type={
            controlType === Risk_Assessment_Result_Control_Type_Enum.Controlled
              ? 'risk_controlled'
              : 'risk_uncontrolled'
          }
          control={control}
          disabled={readOnly}
        />
      )}
      <ControlledTextarea
        defaultRequired={false}
        key={'rationale'}
        name={'Rationale'}
        label={t('Rationale')}
        description={t('Rationale_help')}
        placeholder={t('Rationale_placeholder')}
        control={control}
        disabled={readOnly}
      />
      <ControlledDatePicker
        defaultRequired={true}
        name={'TestDate'}
        key={'testDate'}
        label={t('TestDate')}
        testId={RiskAssessmentResultTestIds.TestDate}
        description={t('TestDate_help')}
        control={control}
        disabled={readOnly}
      />
      <ControlledFileUpload
        key={'newFiles'}
        label={t('newFiles')}
        description={t('newFiles_help')}
        control={control}
        name={'newFiles'}
        saveFilesName={'files'}
        disabled={readOnly}
      />
    </CustomisableForm>
  );
};

export default RiskAssessmentResultForm;
