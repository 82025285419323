import type {
  TypedPropertyFilterQuery,
  TypedPropertyFilterToken,
} from '@risksmart-app/components/Table/tableUtils';
import type { GroupByDatePrecision } from '@risksmart-app/shared/reporting/api/schema';
import type { DataType } from '@risksmart-app/shared/reporting/datasets/types';
import dayjs from 'dayjs';
import { getFieldValue } from 'src/pages/custom-datasources/update/utils';

import type { FieldRequest } from '@/generated/graphql';
import { tablePropertyFilterDateFormat } from '@/utils/dateUtils';

/**
 * Creates a filter property query based on a category value and date precision
 * @param param0
 * @returns
 */
export const getFilterPropertyForCategory = ({
  value,
  x1FieldDatePrecision,
  x1Field,
  x1FieldType,
}: {
  value: unknown;
  x1FieldDatePrecision?: GroupByDatePrecision | null;
  x1Field: FieldRequest;
  x1FieldType: DataType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): TypedPropertyFilterQuery<any> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tokenGroups: TypedPropertyFilterToken<any>[] = [];

  if (x1FieldType === 'date') {
    if (value === null) {
      tokenGroups.push({
        propertyKey: getFieldValue(x1Field),
        operator: '=',
        value: value,
      });
    } else {
      const precision = x1FieldDatePrecision ?? 'day';
      const start = dayjs(value as string).startOf(precision);
      const end = start.add(1, precision);

      tokenGroups.push({
        propertyKey: getFieldValue(x1Field),
        operator: '>=',
        value: start.format(tablePropertyFilterDateFormat),
      });
      tokenGroups.push({
        propertyKey: getFieldValue(x1Field),
        operator: '<',
        value: end.format(tablePropertyFilterDateFormat),
      });
    }
  } else {
    tokenGroups.push({
      propertyKey: getFieldValue(x1Field),
      operator: '=',
      value,
    });
  }

  return {
    operation: 'and',
    tokenGroups,
    tokens: [],
  };
};
