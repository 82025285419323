import type {
  CancelableEventHandler,
  ClickDetail,
} from '@cloudscape-design/components/internal/events';
import Button from '@risksmart-app/components/Button';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigateToInProgress } from './hooks/useNavigateToInProgress';
import { useWizardStore } from './store/useWizardStore';

type WizardButtonProps = {
  riskId: string;
  basePath: string;
  onClick: CancelableEventHandler<ClickDetail>;
};

export const WizardButton: FC<WizardButtonProps> = ({
  riskId,
  onClick,
  basePath,
}) => {
  const { activeWizards } = useWizardStore();
  const { navigateToInProgress } = useNavigateToInProgress();
  const { t } = useTranslation('common', { keyPrefix: 'wizard' });

  const startWizard = (event: CustomEvent<ClickDetail>) => {
    if (activeWizards.has(riskId)) {
      navigateToInProgress(basePath);
    } else {
      onClick(event);
    }
  };

  return (
    <Button variant={'primary'} onClick={startWizard}>
      {`${activeWizards.has(riskId) ? 'Continue' : 'Start'} ${t('wizardName')}`}
    </Button>
  );
};
